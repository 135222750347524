import { LockReset } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { validateEmail } from "../widgets/validation"
import { ETDialogTitle } from "./ETDialogTitle"

export interface RecoveryDialogProps {
    failedRecovery?: boolean
    messages: string[]
    onStartRecovery: (email: string) => void
}

export const RecoveryDialog = (props: RecoveryDialogProps) => {
    const { failedRecovery, messages, onStartRecovery } = props

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")

    const { t } = useTranslation()
    const theme = useTheme()

    const hasError = () => !checkEmail(email)

    const onClickRecover = () => {
        if (hasError()) {
            return
        }
        onStartRecovery(email)
    }

    const setEmailChecked = (s: string) => {
        setEmail(s)
        checkEmail(s)
    }
    const checkEmail = (s?: string) => validateEmail(s, t, setEmailError)

    return (
        <Dialog aria-labelledby="recovery-dialog-title" maxWidth="xs" open fullWidth>
            <ETDialogTitle title={t("dialog.password_reset.title")} />
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography>{t("message.fill_in_account_email")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Email"
                            label={t("field.email")}
                            autoComplete="email"
                            autoCorrect="off"
                            autoCapitalize="none"
                            type="email"
                            error={!!emailError}
                            helperText={emailError}
                            onChange={(event) => setEmailChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <>
                        {failedRecovery && messages.length === 0 && (
                            <Grid key="failed-recovery-message" item xs={12}>
                                <Typography color={theme.palette.error.main}>
                                    {t("message.expired_recovery_link")}
                                </Typography>
                            </Grid>
                        )}
                        {messages.map((message, i) => (
                            <Grid key={i} item xs={12}>
                                <Typography color={theme.palette.error.main}>{message}</Typography>
                            </Grid>
                        ))}
                    </>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<LockReset />} onClick={onClickRecover} variant="contained" color="primary">
                    {t("action.reset_password")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
