import { ListItemIcon, ListItemText, MenuItem, useTheme } from "@mui/material"
import { Link as ReactLink } from "react-router-dom"
import { hasOffering, offeringOperation, OfferingType, Unit } from "../api/Customer"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import { menuIndent } from "../config/sizing"
import { offeringPath, retainConfig } from "../config/urls"
import { UnitIcon } from "./UnitIcon"

export interface SiteUnitsMenuItemProps {
    unit: Unit
    offering?: OfferingType
    indent?: number
    selected?: boolean
    onClose: () => void
}

export const SiteUnitsMenuItem = (props: SiteUnitsMenuItemProps) => {
    const { unit, offering, indent, selected, onClose } = props

    const theme = useTheme()

    const allowed = useUnitPermission(offeringOperation(offering), unit)

    return (
        <MenuItem
            component={ReactLink}
            to={retainConfig({ pathname: offeringPath(unit, offering) })}
            disabled={!(allowed && hasOffering(unit, offering))}
            onClick={onClose}
            selected={selected}
            sx={{ pl: menuIndent(indent, theme), backgroundColor: theme.palette.background.paper }}
        >
            <ListItemIcon>
                <UnitIcon unit={unit} />
            </ListItemIcon>
            <ListItemText>{unit.DisplayName}</ListItemText>
        </MenuItem>
    )
}
