import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { cloneElement, ReactElement, ReactNode, useState } from "react"

export interface CollapsingHeaderProps {
    icon: ReactElement
    title: string
    hideTooltip: string
    showTooltip: string
    initialExpanded: boolean
    children: ReactNode
}

export const CollapsingHeader = (props: CollapsingHeaderProps) => {
    const { icon, title, hideTooltip, showTooltip, initialExpanded, children } = props

    const [expanded, setExpanded] = useState(initialExpanded)

    const theme = useTheme()
    const isXS = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Grid item xs={12}>
            <Grid container spacing={1} pb={5}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        {cloneElement(icon, { htmlColor: theme.palette.text.secondary })}
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        <Box flexGrow={1} />
                        <Tooltip title={expanded ? hideTooltip : showTooltip} disableInteractive>
                            <IconButton color="primary" onClick={() => setExpanded((s) => !s)}>
                                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Grid>
                {!isXS && expanded && <Grid item sm={6} md={8} lg={9} />}
                {expanded && children}
            </Grid>
        </Grid>
    )
}
