import { Grid, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { Unit } from "../../api/Customer"
import { http } from "../../backend/request"
import { Before, ToShortLocalDateTime, ToShortLocalTime } from "../../config/time"
import { monoEndpointURL } from "../../config/urls"
import { EventPayload, ListEventsResponse } from "../../api/Event"
import { ValidValue } from "../../services/IoT"
import { itemKey } from "../../services/AlarmEvents"
import { Box, Stack } from "@mui/system"
import chroma from "chroma-js"

interface Value {
    time: number
    value: number
}

export interface Ruler {
    value: number
    label: string
    color: string
    labelOnly?: boolean
}

export interface Channel {
    id: number
    thing: string
    item: string
    label: string
    color?: string
}

export interface MultiChartProps {
    siteID: number
    unit: Unit
    itemValues: ValidValue[]
    label: (id: number, annotations?: Map<string, string>) => string
    timeOffsetMS: number
    valueUnit: string
    colorPalette: string[]
    rulers?: Ruler[]
    startValue?: number
    endValue?: number
}

const LIMIT = 10000
const regexThingName = /_(\d+)$/

const areThingsEqual = (arrayA: { thing: string }[], arrayB: { thing: string }[]): boolean => {
    if (arrayA.length !== arrayB.length) {
        return false
    }

    const setA = new Set(arrayA.map((item) => item.thing))
    return arrayB.every((item) => setA.has(item.thing))
}

const validValuesToChannels = (
    itemValues: ValidValue[],
    label: (id: number, annotations?: Map<string, string>) => string,
    colorPalette: string[]
): Channel[] => {
    if (itemValues === undefined) {
        return []
    }

    const colors = chroma.scale(colorPalette).colors(itemValues.length)

    return itemValues.reduce((result: Channel[], item) => {
        const matches = item.thing.match(regexThingName)
        if (matches) {
            const thingID = matches[1]
            result.push({
                id: +thingID,
                thing: item.thing,
                item: item.item,
                label: label(+thingID, item.annotations),
                color: colors[+thingID - 1],
            })
        }
        return result
    }, [])
}

const toValues = (data: EventPayload[]): Value[] => {
    if (data === undefined) {
        return []
    }

    return data.reduce((result: Value[], d) => {
        const time = d.value?.value.timestamp
        const value = d.value?.value.number

        if (time !== undefined && value !== undefined) {
            const timestamp = new Date(time).getTime()
            result.push({
                time: timestamp,
                value: value,
            })
        }
        return result
    }, [])
}

const createQueryURL = (
    siteID: number,
    unit: string,
    thing: string,
    item: string,
    timeOffsetMS: number,
    continuation: string
) =>
    monoEndpointURL(
        `sites/${siteID}/events?unit=${unit}&thing=${thing}&item=${item}&start=${new Date(
            Before(timeOffsetMS)
        ).toISOString()}&continuation=${continuation}&limit=${LIMIT}`
    )

export function NewMultiChart(props: MultiChartProps) {
    const { siteID, unit, itemValues, label, timeOffsetMS, valueUnit, colorPalette, rulers, startValue, endValue } =
        props

    const [data, setData] = useState(new Map<string, Value[]>())
    const [channels, setChannels] = useState<Channel[]>([])
    const [valueStream, setValueStream] = useState(new Map<string, Value>())
    const [loadingData, setLoadingData] = useState(true)

    const snackbar = useSnackbar()
    const theme = useTheme()

    useEffect(() => {
        if (areThingsEqual(channels, itemValues)) {
            return
        }

        setChannels(validValuesToChannels(itemValues, label, colorPalette))
    }, [itemValues, label, channels, colorPalette])

    useEffect(() => {
        setValueStream((prev) => {
            const newValues = new Map<string, Value>(prev)
            itemValues.forEach((i) => {
                if (i.value === undefined) {
                    return
                }
                newValues.set(itemKey(i.thing, i.item), { time: i.timestamp.getTime(), value: i.value })
            })

            return newValues
        })
    }, [itemValues])

    useEffect(() => {
        if (channels.length === 0) {
            return
        }

        setLoadingData(true)
        const newData = new Map<string, Value[]>()

        const fetchData = async (channel: Channel) => {
            let hasMore = true
            let continuation = ""
            const key = itemKey(channel.thing, channel.item)

            while (hasMore) {
                try {
                    const result = await http<ListEventsResponse>(
                        "Getting timeseries",
                        createQueryURL(siteID, unit.ShortName, channel.thing, channel.item, timeOffsetMS, continuation),
                        snackbar
                    )

                    const prevValues = newData.get(key) || []
                    const newValues = toValues(result.data)

                    continuation = result.pagination?.continuation
                    hasMore = continuation ? true : false

                    newData.set(key, [...prevValues, ...newValues])
                } catch (error) {
                    console.error(`Error fetching data for ${key}:`, error)
                    hasMore = false
                }
            }

            // Adds the first known value at the start of the timerange to avoid gaps at the start of the chart.
            const prevData = newData.get(key)
            if (prevData === undefined || prevData.length === 0) {
                return
            }

            const oldestData = prevData[prevData.length - 1]
            const startTime = Date.now() - timeOffsetMS
            if (oldestData.time > startTime) {
                newData.set(key, [...prevData, { time: startTime, value: oldestData.value }])
            }
        }

        Promise.all(channels.map(fetchData))
            .then(() => setData(newData))
            .finally(() => setLoadingData(false))
    }, [snackbar, siteID, unit, timeOffsetMS, channels])

    const allValues = useMemo(
        () => (data ? Array.from(data.values()).flatMap((series) => series.map((v) => v.value)) : []),
        [data]
    )
    const allTimes = useMemo(
        () => (data ? Array.from(data.values()).flatMap((series) => series.map((v) => v.time)) : []),
        [data]
    )
    const allRulers = useMemo(() => (rulers ? rulers.map((r) => r.value) : []), [rulers])
    const timeRange = useMemo(
        () => [Math.min(...allTimes, new Date().getTime() - timeOffsetMS), Math.max(...allTimes, new Date().getTime())],
        [allTimes, timeOffsetMS]
    )
    const valueRange = useMemo(
        () => [
            startValue !== undefined
                ? Math.floor(Math.min(startValue, ...allValues, ...allRulers))
                : Math.floor(Math.min(...allValues, ...allRulers)),
            endValue !== undefined
                ? Math.ceil(Math.max(endValue, ...allValues, ...allRulers))
                : Math.ceil(Math.max(...allValues, ...allRulers)),
        ],
        [allValues, allRulers, startValue, endValue]
    )

    const valueFormatter = useCallback((s: any) => (+s).toFixed(2) + " " + valueUnit, [valueUnit])

    useEffect(() => {
        if (loadingData) {
            return
        }

        setData((prevMap) => {
            const updatedMap = new Map(prevMap)

            channels.forEach((channel) => {
                const key = itemKey(channel.thing, channel.item)
                const prevData = updatedMap.get(key) || []

                const newTime = valueStream.get(key)?.time
                const newValue = valueStream.get(key)?.value
                if (newTime === undefined || newValue === undefined) {
                    return
                }

                const newData: Value = {
                    time: newTime,
                    value: newValue,
                }

                const timeNow = Date.now()
                const startTime = timeNow - timeOffsetMS
                if (newTime < startTime) {
                    newData.time = startTime
                }

                if (prevData.length === 0) {
                    // Add duplicate at the end to make chart look complete.
                    const endData: Value = {
                        time: timeNow,
                        value: newData.value,
                    }
                    updatedMap.set(key, [newData, endData])
                    return updatedMap
                }

                const existingTimes = new Set(prevData.map((entry) => entry.time))
                if (existingTimes.has(newData.time)) {
                    newData.time = timeNow
                }

                updatedMap.set(key, [newData, ...prevData])
            })
            return updatedMap
        })
    }, [channels, valueStream, timeOffsetMS, loadingData])

    return (
        <>
            {channels.map((channel) => (
                <Grid item xs={12} sm={6} md={3} lg={2} key={channel.id}>
                    <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                        <Typography>{channel.label}</Typography>
                        <Typography color={channel.color}>
                            {valueStream.get(itemKey(channel.thing, channel.item))?.value + valueUnit}
                        </Typography>
                    </Stack>
                </Grid>
            ))}

            <Grid item xs={12}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        width: "100%",
                        height: {
                            xs: "150px",
                            sm: "200px",
                            md: "250px",
                            lg: "300px",
                        },
                    }}
                    p={1}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart margin={{ top: 20, right: 20, left: 5, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="1 4" opacity={0.2} />
                            <Tooltip
                                isAnimationActive={false}
                                contentStyle={{
                                    backgroundColor: theme.palette.grey[800],
                                    color: theme.palette.grey[50],
                                }}
                                labelFormatter={ToShortLocalDateTime}
                                formatter={valueFormatter}
                                cursor={false}
                            />
                            <XAxis
                                dataKey="time"
                                fontSize="small"
                                scale="time"
                                tickFormatter={ToShortLocalTime}
                                type="number"
                                domain={timeRange}
                                allowDuplicatedCategory={false}
                                interval="preserveStartEnd"
                            />
                            <YAxis
                                unit={" " + valueUnit}
                                fontSize="small"
                                width={55}
                                dataKey="value"
                                domain={valueRange}
                                scale="linear"
                            />
                            {channels.map((channel, idx) => {
                                const series = data?.get(itemKey(channel.thing, channel.item))
                                if (!series || series.length === 0) {
                                    return null
                                }
                                return (
                                    <Line
                                        key={`line-${channel.thing}`}
                                        isAnimationActive={false}
                                        type="monotone"
                                        dataKey="value"
                                        data={series.sort((a, b) => a.time - b.time)}
                                        name={channel.label}
                                        stroke={channel.color}
                                        opacity={1}
                                        dot={false}
                                        strokeWidth={2}
                                        scale="linear"
                                        allowReorder="yes"
                                        xAxisId={0}
                                        yAxisId={0}
                                    />
                                )
                            })}
                            {rulers &&
                                rulers.map((r) => (
                                    <ReferenceLine
                                        key={r.label}
                                        y={r.value}
                                        stroke={r.labelOnly ? "transparent" : r.color}
                                        strokeDasharray="4 1"
                                    >
                                        <Label fontSize="0.7em" value={r.label} fill={r.color} position="top" />
                                    </ReferenceLine>
                                ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </>
    )
}
