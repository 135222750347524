import { Event, EventType, EventTypeID } from "../api/Event"
import { equalArrays } from "../config/array"
import { equalSets } from "../config/set"
import { AllIDs, Node } from "../hooks/treeset"

export const Leaf = (t: EventType): Node<EventType> => {
    return { id: t.id, value: t }
}

export type EventFilter = {
    enabled: boolean
    ids: Set<string>
    includes: (string | RegExp)[]
    excludes: (string | RegExp)[]
}

export const NewFilter = (
    enabled: boolean,
    ids: Set<string>,
    includes: (string | RegExp)[],
    excludes: (string | RegExp)[]
) => {
    return { enabled, ids, includes, excludes }
}

const matchWord = (s: string, exp: string | RegExp) => (exp instanceof RegExp ? exp.test(s) : s.includes(exp))

export const MatchEvent = (filter: EventFilter, event: Event) =>
    filter.ids.has(EventTypeID(event.labels?.get("class") || "", event.item, event.value || 0)) &&
    (filter.includes.length === 0 || filter.includes.some((exp) => matchWord(event.message, exp))) &&
    !filter.excludes.some((exp) => matchWord(event.message, exp))

export const EqualFilter = (f1: EventFilter, f2: EventFilter) =>
    f1.enabled === f2.enabled &&
    equalSets(f1.ids, f2.ids) &&
    equalArrays(f1.includes, f2.includes) &&
    equalArrays(f1.excludes, f2.excludes)

export const IsMatchAll = (filter: EventFilter, filterTree: Node<EventType>) =>
    filter.enabled &&
    equalSets(filter.ids, AllIDs(filterTree)) &&
    filter.includes.length === 0 &&
    filter.excludes.length === 0
