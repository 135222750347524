import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { View } from "../../config/paging"

export interface CameraViewChoiceProps {
    value: View
    choices: View[]
    orientation: "vertical" | "horizontal"
    onChange: (view: View) => void
}

export const CameraViewChoice = (props: CameraViewChoiceProps) => {
    const { value, choices, orientation, onChange } = props

    return (
        <ToggleButtonGroup
            value={value}
            orientation={orientation}
            exclusive={true}
            onChange={(_, v) => (v && v !== value ? onChange(v) : undefined)}
            color="primary"
        >
            {choices.map((c) => (
                <ToggleButton key={c.key} sx={{ padding: "0.2em" }} size="small" value={c} color="primary">
                    <Typography minWidth="18px" variant="caption" fontWeight="bold">
                        {c.name}
                    </Typography>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
