import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link as ReactLink } from "react-router-dom"
import { asUnitSubject } from "../api/Authz"
import { hasOffering, offeringOperation, OfferingType, Unit } from "../api/Customer"
import useAuthorizer from "../auth/AuthorizerProvider"
import { offeringPath, retainConfig } from "../config/urls"
import { ServiceIcon } from "./ServiceIcon"

export interface UnitServicesMenuProps {
    unit: Unit
    offering?: OfferingType
    anchorEl: Element | null
    open: boolean
    onClose: () => void
}

export const UnitServicesMenu = (props: UnitServicesMenuProps) => {
    const { unit, offering, anchorEl, open, onClose } = props

    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()

    const renderMenuItem = (o: OfferingType, title: string) => (
        <MenuItem
            component={ReactLink}
            to={retainConfig({ pathname: offeringPath(unit, o) })}
            disabled={!(allowOperation(offeringOperation(o), asUnitSubject(unit)) && hasOffering(unit, o))}
            onClick={onClose}
            selected={o === offering}
        >
            <ListItemIcon>
                <ServiceIcon offering={o} />
            </ListItemIcon>
            <ListItemText>{title}</ListItemText>
        </MenuItem>
    )

    return (
        <Menu keepMounted anchorEl={anchorEl} open={open} onClose={onClose}>
            {renderMenuItem(OfferingType.Access, t("offering.access"))}
            {renderMenuItem(OfferingType.Alarm, t("offering.alarm"))}
            {renderMenuItem(OfferingType.Video, t("offering.video"))}
            {renderMenuItem(OfferingType.Weather, t("offering.weather"))}
            {renderMenuItem(OfferingType.Statistics, t("offering.statistics"))}
            {renderMenuItem(OfferingType.Tracking, t("offering.tracking"))}
            {renderMenuItem(OfferingType.Timelapse, t("offering.timelapse"))}
        </Menu>
    )
}
