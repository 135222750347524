import { FlashlightOn, MeetingRoom, Settings } from "@mui/icons-material"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { Site, Unit } from "../../api/Customer"
import { useSitePermission, useUnitPermission } from "../../auth/AuthorizerProvider"
import { thingIndex, useIoT, useIoTCommands, useIoTValues } from "../../services/IoT"
import { ConnectionStatus } from "../common/ConnectionStatus"
import { LightInfo } from "../newalarm/LightInfo"
import { CollapsingHeader } from "./CollapsingHeader"
import { GateInfo } from "./GateInfo"

export interface NewAccessProps {
    site: Site
    unit: Unit
}

const classes = ["light", "gate"]

export const NewAccess = (props: NewAccessProps) => {
    const { site, unit } = props

    const { online, clients, items, values } = useIoT(site, unit.ShortName, true, classes)
    const lights = useIoTValues("light", "switch", clients, items, values)
    const gates = useIoTValues("gate", "switch", clients, items, values)
    const { send } = useIoTCommands(site)

    const { t } = useTranslation()
    const allowManageAccess = useSitePermission(Operation.MANAGE_SITE_ACCESS, site)
    const allowLightOperation = useUnitPermission(Operation.SWITCH_LIGHT, unit)

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <ConnectionStatus online={online} />
                <Grid container spacing={1}>
                    {gates.length > 0 && (
                        <CollapsingHeader
                            icon={<MeetingRoom />}
                            initialExpanded={true}
                            title={t("access.gates")}
                            showTooltip={t("unit.show_gates_tooltip")}
                            hideTooltip={t("unit.hide_gates_tooltip")}
                        >
                            {gates.map((gate) => (
                                <GateInfo gate={gate} site={site} unit={unit} />
                            ))}
                        </CollapsingHeader>
                    )}

                    {lights.length > 0 && (
                        <CollapsingHeader
                            icon={<FlashlightOn />}
                            initialExpanded={true}
                            title={t("unit.lights")}
                            showTooltip={t("unit.show_lights_tooltip")}
                            hideTooltip={t("unit.hide_lights_tooltip")}
                        >
                            {lights.map((light) => (
                                <LightInfo
                                    key={light.thing}
                                    id={thingIndex(light.thing)}
                                    value={light.value === undefined ? undefined : light.value === 1}
                                    allowed={allowLightOperation}
                                    displayName={light.annotations?.get("display_name")}
                                    flipSwitch={(on) => send(light.thing, light.item, on ? 0 : 1)}
                                />
                            ))}
                        </CollapsingHeader>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="h6">{t("action.settings")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            startIcon={<Settings sx={{ mr: 0.5 }} />}
                            onClick={() => window.open(`https://acs.eyetowers.io`, "_blank")}
                            disabled={!allowManageAccess}
                        >
                            {t("access.management")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}
