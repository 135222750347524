
export interface View {
    name: string,
    key: string
    columns: number
    size?: number
}

const view = (name: string, key: string, columns: number, size?: number) => ({ name, key, columns, size })

export const Grid1x1 = view("1", "grid1x1", 1, 1)
export const Grid1x2 = view("2", "grid1x2", 1, 2)
export const Grid1x4 = view("4", "grid1x4", 1, 4)
export const Grid2x1 = view("2", "grid2x1", 2, 2)
export const Grid2x2 = view("4", "grid2x2", 2, 4)
export const Grid2x4 = view("8", "grid2x4", 2, 8)
export const Grid3x3 = view("9", "grid3x3", 3, 9)
export const Grid3x5 = view("15", "grid3x5", 3, 15)
export const Grid4x4 = view("16", "grid4x4", 4, 16)
export const Scroll1 = view("1", "scroll1", 1)
export const Scroll2 = view("2", "scroll2", 2)
export const Scroll3 = view("3", "scroll3", 3)

const views = new Map([
    Grid1x1,
    Grid1x2,
    Grid1x4,
    Grid2x1,
    Grid2x2,
    Grid2x4,
    Grid3x3,
    Grid3x5,
    Grid4x4,
    Scroll1,
    Scroll2,
    Scroll3,
].map(v => [v.key, v]))

export const ViewOrDefault = (key: string, defaultView: View) => views.get(key) || defaultView

export interface Paging {
    View: View
    Pinned: number[]
    Unpinned: number[]
    Page: number
    Pages: number
    Count: number
}

export const WithView = (paging: Paging, view: View): Paging => {
    return RecalculatePaging(paging, {
        ...paging,
        View: view,
    })
}

export const WithPage = (paging: Paging, page: number): Paging => {
    page = Math.max(0, Math.min(paging.Pages - 1, page))
    return {
        ...paging,
        Page: page,
    }
}

export const WithPinned = (paging: Paging, pinned: number[]): Paging => {
    const unpinned = Unpinned(pinned, paging.Count)
    return RecalculatePaging(paging, {
        ...paging,
        Pinned: pinned,
        Unpinned: unpinned,
    })
}

export const Unpinned = (pinned: number[], count: number) =>
    Array.from({ length: count }, (_, i) => i).filter(i => pinned.every(j => i !== j))

const RecalculatePaging = (old: Paging, updated: Paging) => {
    const actualSize = ActualPageSize(updated)
    const pages = ActualPages(updated)
    if (!pages || !actualSize) {
        return {
            ...updated,
            Page: 0,
            Pages: 0,
        }
    }
    const oldActualSize = ActualPageSize(old)
    if (oldActualSize === 0) {
        return {
            ...updated,
            Page: 0,
            Pages: pages,
        }
    }
    const oldFirstUnpinnedIndex = FirstUnpinnedIndex(old)
    const page = Math.floor(oldFirstUnpinnedIndex / actualSize)
    return {
        ...updated,
        Page: page,
        Pages: pages,
    }
}

const ActualPageSize = (paging: Paging) => {
    if (!paging.View.size) {
        return 0
    }
    const result = paging.View.size - paging.Pinned.length
    if (result <= 0) {
        return 0
    }
    return result
}

export const ActualPages = (paging: Paging) => {
    const size = ActualPageSize(paging)
    if (size === 0) {
        return undefined
    }
    return Math.ceil(paging.Unpinned.length / size)
}

const FirstUnpinnedIndex = (paging: Paging) => {
    const size = ActualPageSize(paging)
    if (!size) {
        return 0
    }
    return size * paging.Page
}

export const ActualCameraIndex = (paging: Paging, i: number) => {
    if (i < paging.Pinned.length) {
        return paging.Pinned[i]
    }
    const actualSize = ActualPageSize(paging)
    const actualIndex = i - paging.Pinned.length
    const unpinnedIndex = actualSize * paging.Page + actualIndex

    if (unpinnedIndex >= paging.Unpinned.length) {
        return -1
    }
    return paging.Unpinned[unpinnedIndex]
}

export const EqualPaging = (p1: Paging, p2: Paging) => {
    if (
        p1.Count !== p2.Count ||
        p1.Page !== p2.Page ||
        p1.View !== p2.View ||
        p1.Pages !== p2.Pages ||
        p1.Pinned.length !== p2.Pinned.length
    ) {
        return false
    }
    return p1.Pinned.every((v, i) => p2.Pinned[i] === v)
}

export const SafePaging = (
    view: View,
    recommendedView: View,
    allowedViews: View[],
    page: number,
    count: number,
    pinned: number[]
): Paging => {
    view = SanitizeView(view, recommendedView, allowedViews, count)
    pinned = SanitizePinned(pinned, count)
    const unpinned = Unpinned(pinned, count)

    const withoutPaging = {
        View: view,
        Page: 0,
        Pages: 0,
        Pinned: pinned,
        Unpinned: unpinned,
        Count: count,
    }
    const pages = ActualPages(withoutPaging)
    if (!pages) {
        return withoutPaging
    }
    page = Math.max(0, Math.min(pages - 1, page))
    return {
        ...withoutPaging,
        Page: page,
        Pages: pages,
    }
}

export const SanitizePinned = (pinned: number[], count: number) => {
    return pinned.filter(
        (v, i) => v >= 0 && v < count && v === Math.floor(v) && pinned.slice(0, i).every((b) => b !== v)
    )
}

export const DefaultView = (recommendedView: View, allowedViews: View[], count: number) => {
    if (!recommendedView.size) {
        // Scrolling view.
        const bestFit = allowedViews.find((v) => v.columns >= count) || recommendedView
        if (bestFit.columns < recommendedView.columns) {
            return bestFit
        }
    } else {
        const bestFit = allowedViews.find((v) => v.size && v.size >= count) || recommendedView
        if (bestFit.size && bestFit.size < recommendedView.size) {
            return bestFit
        }
    }
    return recommendedView
}

export const IsFullyPinned = (paging: Paging) => paging.View.size && paging.Count > paging.View.size && paging.Pages === 0

export const SanitizeView = (
    view: View | undefined,
    recommendedView: View,
    allowedViews: View[],
    count: number
) => {
    if (!view) {
        // Unspecified view, let's go to the default.
        return DefaultView(recommendedView, allowedViews, count)
    }
    if (allowedViews.some(v => v === view)) {
        // The required view is allowed, so pick it.
        return view
    }
    // First equal or bigger size or the default.
    return (
        allowedViews.reduce((prev: View | undefined, v) => v.columns <= view.columns ? v : prev, undefined) ||
        allowedViews.find((v) => v.columns > view.columns) ||
        DefaultView(recommendedView, allowedViews, count)
    )
}
