import { Domain, ExpandLess, ExpandMore, LocationOn, Search } from "@mui/icons-material"
import { Collapse, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material"
import { ReactElement, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { compareUnits, Customer, OfferingType, Site, Unit } from "../api/Customer"
import { menuIndent } from "../config/sizing"
import { retainConfig } from "../config/urls"
import { SiteUnitsMenuItem } from "./SiteUnitsMenuItem"

export interface SiteUnitsMenuProps {
    customer: Customer
    unit?: Unit
    site: Site
    customers: Customer[]
    offering?: OfferingType
    anchorEl: Element | null
    open: boolean
    onClose: () => void
}

enum View {
    None,
    CurrentSite,
    AllSites,
    AllUnits,
}

export const SiteUnitsMenu = (props: SiteUnitsMenuProps) => {
    const { customer, unit, site, customers, offering, anchorEl, open, onClose } = props

    const [view, setView] = useState(View.CurrentSite)

    const theme = useTheme()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const currentUnits = useMemo(
        () => customer.Units.filter((u) => u.SiteID === site.ID).sort(compareUnits),
        [customer, site]
    )
    const allUnits = useMemo(() => customers.flatMap((c) => c.Units).sort(compareUnits), [customers])

    const navigateToSite = (s: Site) => {
        doClose()
        navigate(retainConfig({ pathname: "/", hash: `site-${s.ID}` }))
    }

    const navigateToCustomer = (c: Customer) => {
        doClose()
        navigate(retainConfig({ pathname: "/", hash: `customer-${c.ID}` }))
    }

    const doClose = () => {
        setView(View.CurrentSite)
        onClose()
    }

    const renderItem = (key: string, indent: number, message: string, handler?: () => void, icon?: ReactElement) => (
        <MenuItem
            key={key}
            onClick={handler}
            sx={{ pl: menuIndent(indent, theme), backgroundColor: theme.palette.background.paper }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{message}</ListItemText>
        </MenuItem>
    )

    const renderCustomer = (c: Customer) => {
        if (c.Units.length === 0) {
            return []
        }
        const customerSites = c.Sites.filter((s) => !s.Archived)
        return [
            renderItem(`customer-${c.ID}`, 1, c.DisplayName, () => navigateToCustomer(c), <Domain fontSize="small" />),
            ...customerSites.flatMap((s) => renderSite(s, c)),
        ]
    }

    const renderSite = (s: Site, c: Customer) => {
        const siteUnits = c.Units.filter((u) => u.SiteID === s.ID).sort(compareUnits)
        if (siteUnits.length === 0) {
            return []
        }
        return [
            renderItem(`site-${s.ID}`, 2, s.DisplayName, () => navigateToSite(s), <LocationOn fontSize="small" />),
            ...siteUnits.map((u) => (
                <SiteUnitsMenuItem
                    key={`unit-${u.ShortName}`}
                    selected={unit?.ID === u.ID}
                    indent={3}
                    unit={u}
                    offering={offering}
                    onClose={doClose}
                />
            )),
        ]
    }

    const toggleView = (target: View) => setView((v) => (v === target ? View.None : target))

    const renderToggle = (key: string, message: string, target: View, children: ReactElement[]) => [
        <MenuItem key={key} onClick={() => toggleView(target)}>
            <ListItemIcon>
                <Search fontSize="small" />
            </ListItemIcon>
            <ListItemText>{message}</ListItemText>
            <ListItemIcon>{view === target ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        </MenuItem>,
        <Collapse key={key + "-collapse"} in={view === target} timeout={0} unmountOnExit>
            {children}
        </Collapse>,
    ]

    return (
        <Menu keepMounted anchorEl={anchorEl} open={open} onClose={doClose}>
            {renderToggle(
                "local-units",
                t("action.show_local_units"),
                View.CurrentSite,
                currentUnits.map((u) => (
                    <SiteUnitsMenuItem
                        key={u.ShortName}
                        unit={u}
                        offering={offering}
                        onClose={doClose}
                        selected={unit?.ID === u.ID}
                        indent={1}
                    />
                ))
            )}
            {renderToggle("all-sites", t("action.show_all_sites"), View.AllSites, customers.flatMap(renderCustomer))}
            {renderToggle(
                "all-units",
                t("action.show_all_units"),
                View.AllUnits,
                allUnits.map((u) => (
                    <SiteUnitsMenuItem
                        unit={u}
                        selected={u.ID === unit?.ID}
                        key={u.ID}
                        offering={offering}
                        onClose={doClose}
                        indent={1}
                    />
                ))
            )}
        </Menu>
    )
}
