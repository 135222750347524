import { Theme } from "@mui/material"
import { BaseSyntheticEvent } from "react"

export const minPageWidth = "350px"

export const fillAvailable = "streatch,fill-available,-moz-available,-webkit-fill-available,100%"

const ignore = (e: BaseSyntheticEvent<any>) => e.stopPropagation()

export const wideIncativeTooltip = {
    tooltip: {
        sx: { maxWidth: 800 },
        onClick: ignore,
        onMouseDown: ignore,
    }
}

export const menuIndent = (size: number | undefined, theme: Theme) =>
    size ? theme.spacing(2 + 2 * size) : undefined
