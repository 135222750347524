import { Domain, Home, LocationOn, MoreVert } from "@mui/icons-material"
import {
    AppBar,
    Box,
    Breadcrumbs,
    Divider,
    IconButton,
    Link,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import { Stack } from "@mui/system"
import { cloneElement, ReactElement, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as ReactLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { Customer, OfferingType, Site, Theme, Unit } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import useConfig from "../config/Provider"
import { minPageWidth } from "../config/sizing"
import { retainConfig, staticURL } from "../config/urls"
import { commit, version } from "../config/version"
import { AccountMenu } from "./AccountMenu"
import { SiteUnitsMenu } from "./SiteUnitsMenu"
import { UnitIcon } from "./UnitIcon"
import { UnitServicesMenu } from "./UnitServicesMenu"

export interface Breadcrumb {
    title: string
    icon: ReactElement
}

export interface UnitAppBarProps {
    whoAmI: WhoAmI
    customer?: Customer
    site?: Site
    unit?: Unit
    page?: Breadcrumb
    offering?: OfferingType
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

export function UnitAppBar(props: UnitAppBarProps) {
    const { whoAmI, customer, site, unit, page, offering, customers, showArchived, setShowArchived } = props

    const [unitsMenuOpen, setUnitsMenuOpen] = useState(false)
    const [servicesMenuOpen, setServicesMenuOpen] = useState(false)
    const unitsMenuAnchor = useRef(null)
    const servicesMenuAnchor = useRef(null)

    const { t } = useTranslation()
    const theme = useTheme()
    const { embedded } = useConfig()

    const branding = useMemo(
        () => ({
            DefaultMode: whoAmI.Branding?.DefaultTheme || Theme.DARK,
            Logos: whoAmI.Branding?.Logos || [],
        }),
        [whoAmI]
    )
    const logo = useMemo(
        () => branding.Logos.find((l) => l.Theme === theme.palette.mode.toUpperCase()),
        [branding, theme]
    )
    const smallLogoURL = useMemo(
        () => logo?.SmallURL || staticURL(theme.palette.mode === "dark" ? "logo-small.svg" : "logo-small-light.svg"),
        [theme, logo]
    )
    const largeLogoURL = useMemo(
        () => logo?.LargeURL || staticURL(theme.palette.mode === "dark" ? "logo.svg" : "logo-light.svg"),
        [theme, logo]
    )

    const renderBuildInfo = () => (
        <Stack>
            <Typography fontSize="small" fontWeight="bold">
                EyeTowers Portal
            </Typography>
            <Divider sx={{ mb: "0.5rem" }} />
            <Typography fontSize="small">
                <span style={{ color: "lightgray" }}>Version:</span> {version || "unknown"}
            </Typography>
            <Typography fontSize="small">
                <span style={{ color: "lightgray" }}>Commit:</span> {commit || "unknown"}
            </Typography>
        </Stack>
    )

    const renderLinkIconItem = (icon: ReactElement, path: string, hash?: string) => (
        <Link
            underline="hover"
            color="inherit"
            to={retainConfig({
                pathname: path,
                hash: hash,
            })}
            component={hash ? HashLink : ReactLink}
        >
            <Stack direction="row" alignItems="center">
                {cloneElement(icon, {
                    fontSize: "small",
                    htmlColor: theme.palette.text.secondary,
                })}
            </Stack>
        </Link>
    )

    const renderLinkItem = (
        icon: ReactElement,
        title: string,
        hide: boolean,
        path: string,
        hash?: string,
        component?: ReactElement
    ) => (
        <Link
            underline="hover"
            color="inherit"
            to={retainConfig({
                pathname: path,
                hash: hash,
            })}
            component={hash ? HashLink : ReactLink}
        >
            <Stack direction="row" alignItems="center" spacing={{ xs: hide ? 0 : 0.5, md: 0.5 }}>
                {cloneElement(icon, {
                    fontSize: "small",
                    htmlColor: theme.palette.text.secondary,
                })}
                <Typography noWrap display={{ xs: hide ? "none" : "inherit", md: "inherit" }}>
                    {title}
                </Typography>
                {component}
            </Stack>
        </Link>
    )

    const renderTextItem = (icon: ReactElement, title: string) => (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            {cloneElement(icon, {
                fontSize: "small",
                htmlColor: theme.palette.text.secondary,
            })}
            <Typography noWrap>{title}</Typography>
        </Stack>
    )

    const renderBody = () => (
        <Stack
            direction="row"
            width="100%"
            minWidth={minPageWidth}
            pt={0.5}
            spacing={{ xs: 0.5, sm: 1 }}
            alignItems="center"
        >
            <Tooltip title={renderBuildInfo()} disableInteractive>
                <Box
                    component="img"
                    sx={{
                        maxHeight: 38,
                        content: {
                            xs: `url(${smallLogoURL})`,
                            sm: `url(${largeLogoURL})`,
                        },
                    }}
                    pr={1}
                    alt="Logo"
                />
            </Tooltip>
            <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                sx={{
                    overflow: "hidden",
                    flex: 1,
                    py: 1,
                }}
            >
                {renderLinkIconItem(<Home />, "/")}
                {customer && renderLinkItem(<Domain />, customer.DisplayName, true, "/", `customer-${customer?.ID}`)}
                {site &&
                    renderLinkItem(
                        <LocationOn />,
                        site.DisplayName,
                        true,
                        "/",
                        `site-${site?.ID}`,
                        <Tooltip title={t("action.navigate_units_tooltip")} disableInteractive>
                            <IconButton
                                color="primary"
                                ref={unitsMenuAnchor}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setUnitsMenuOpen(true)
                                }}
                                sx={{ p: 0.3 }}
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                {unit &&
                    renderLinkItem(
                        <UnitIcon unit={unit} />,
                        unit.ShortName,
                        false,
                        "/",
                        unit.ShortName,
                        <Tooltip title={t("action.navigate_services_tooltip")} disableInteractive>
                            <IconButton
                                color="primary"
                                ref={servicesMenuAnchor}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setServicesMenuOpen(true)
                                }}
                                sx={{ p: 0.3 }}
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                {page && renderTextItem(page.icon, page.title)}
            </Breadcrumbs>
            <AccountMenu
                actor={whoAmI.User}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
        </Stack>
    )

    if (embedded) {
        return null
    }

    return (
        <>
            <AppBar color="default">
                <Toolbar variant="dense">{renderBody()}</Toolbar>
            </AppBar>
            {unit && (
                <UnitServicesMenu
                    unit={unit}
                    offering={offering}
                    anchorEl={servicesMenuAnchor.current}
                    open={servicesMenuOpen}
                    onClose={() => setServicesMenuOpen(false)}
                />
            )}
            {site && customer && (
                <SiteUnitsMenu
                    customer={customer}
                    unit={unit}
                    site={site}
                    customers={customers}
                    offering={offering}
                    anchorEl={unitsMenuAnchor.current}
                    open={unitsMenuOpen}
                    onClose={() => setUnitsMenuOpen(false)}
                />
            )}
            {/* Reserve space for the toolbar. */}
            <Stack visibility={"hidden"}>
                <Toolbar variant="dense">{renderBody()}</Toolbar>
            </Stack>
        </>
    )
}
