import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Site } from "../api/Customer"
import { Annotations, asLabels, Labels } from "../api/Event"
import { http, noSnackBar } from "../backend/request"
import { request } from "../config/headers"
import { Second } from "../config/time"
import { monoEndpointURL } from "../config/urls"
import { useBackoff } from "../hooks/backoff"
import { itemKey } from "./AlarmEvents"

const reconnectDelay = 5 * Second
const regexThingsTokenize = /([a-zA-Z]+|\d+|[._])/g

export interface ValidValue {
    item: string
    thing: string
    labels?: Labels
    annotations?: Annotations
    value?: number
    timestamp: Date
}

interface Event {
    client?: Client
    thing?: Thing
    item?: Item
    value?: ItemValue
}

interface Client {
    id: string
    manifest: ClientManifest
}

interface ClientManifest {
    name: string
    status: "CONNECTED" | "DISCONNECTED"
    timestamp: Date
}

interface Thing {
    name: string
    manifest: ThingManifest
}

interface ThingManifest {
    clientId: string
    labels?: Labels
    annotations?: Annotations
    timestamp: Date
}

interface Item {
    name: string
    thing: string
    manifest: ItemManifest
}

interface ItemManifest {
    clientId: string
    labels?: Labels
    annotations?: Annotations
    timestamp: Date
}

export interface ItemValue {
    item: string
    thing: string
    value: Value
}

interface Value {
    number?: number
    labels?: Labels
    clientId: string
    operation: "CHANGED" | "INITIALIZED"
    timestamp: Date
}

const parseEvent = (data: string): Event => {
    const event = JSON.parse(data)

    const client = event["client"]
    const thing = event["thing"]
    const item = event["item"]
    const itemValue = event["value"]

    if (client) {
        const manifest = client["manifest"]

        return {
            client: {
                id: client["id"],
                manifest: {
                    name: manifest["name"],
                    status: manifest["status"],
                    timestamp: new Date(manifest["timestamp"]),
                },
            }
        }
    }
    if (thing) {
        const manifest = thing["manifest"]

        return {
            thing: {
                name: thing["name"],
                manifest: {
                    labels: asLabels(manifest["labels"]),
                    annotations: asLabels(manifest["annotations"]),
                    clientId: manifest["clientId"],
                    timestamp: new Date(manifest["timestamp"]),
                },
            }
        }
    }
    if (item) {
        const manifest = item["manifest"]

        return {
            item: {
                name: item["name"],
                thing: item["thing"],
                manifest: {
                    labels: asLabels(manifest["labels"]),
                    annotations: asLabels(manifest["annotations"]),
                    clientId: manifest["clientId"],
                    timestamp: new Date(manifest["timestamp"]),
                },
            }
        }
    }
    if (itemValue) {
        const value = itemValue["value"]

        return {
            value: {
                item: itemValue["item"],
                thing: itemValue["thing"],
                value: {
                    number: value["number"],
                    labels: asLabels(value["labels"]),
                    clientId: value["clientId"],
                    operation: value["operation"],
                    timestamp: new Date(value["timestamp"]),
                },
            }
        }
    }

    return {}
}

const upsert = <T>(key: string, value: T, map: Map<string, T>) => {
    const copy = new Map(map)
    copy.set(key, value)
    return copy
}

export const useIoT = (
    site: Site,
    unit: string | undefined,
    active: boolean,
    classes: string[],
) => {
    const { t } = useTranslation()

    const [online, setOnline] = useState(false)
    const [clients, setClients] = useState(new Map<string, Client>())
    const [things, setThings] = useState(new Map<string, Thing>())
    const [items, setItems] = useState(new Map<string, Item>())
    const [values, setValues] = useState(new Map<string, ItemValue>())

    const { attempt, retry, cancel } = useBackoff(reconnectDelay)

    const handleConnect = useCallback(() => {
        console.log(`Event stream for site ${site.ID} connected.`)
    }, [site.ID])

    const handleDisconnect = useCallback(() => {
        console.log(`Event stream for site ${site.ID} disconnected.`)
        setOnline(false)
    }, [site.ID])

    const classFilter = useMemo(() => new Set(classes), [classes])
    const filter = useCallback((e: Event) => {
        if (e.client) {
            return true
        }
        const thingClass = (
            e.thing?.manifest.labels?.get("class") ||
            e.item?.manifest.labels?.get("class") ||
            e.value?.value.labels?.get("class") ||
            ""
        )
        return classFilter.has(thingClass)
    }, [classFilter])
    const onMessage = useCallback((e: Event) => console.error("Event: ", e), [])

    useEffect(() => {
        if (!active) {
            return
        }

        const query = unit ? `thing=${unit}.*&` : ""
        const url = monoEndpointURL(`watch/sites/${site.ID}/items?${query}include=value&include=thing&include=client`)
        const source = new EventSource(url)

        var seeded = false
        var seedClients = new Map<string, Client>()
        var seedThings = new Map<string, Thing>()
        var seedItems = new Map<string, Item>()
        var seedValues = new Map<string, ItemValue>()

        source.addEventListener("state", (event) => {
            if (event.type === "state") {
                switch (event.data) {
                    case "seeded":
                        console.log("SSE Event: seeded.", seedValues)
                        if (!seeded) {
                            setClients(seedClients)
                            setThings(seedThings)
                            setItems(seedItems)
                            setValues(seedValues)
                            seedClients = new Map<string, Client>()
                            seedThings = new Map<string, Thing>()
                            seedItems = new Map<string, Item>()
                            seedValues = new Map<string, ItemValue>()
                            seeded = true
                        }
                        return
                    case "new":
                        console.log("SSE Event: new.")
                        setOnline(true)
                        return
                }
                return
            }
        })
        source.addEventListener("message", (event) => {
            const parsed = parseEvent(event.data)
            if (!filter(parsed)) {
                console.log("SSE Event: filtered out.", parsed)
                return
            }

            const client = parsed.client
            const thing = parsed.thing
            const item = parsed.item
            const value = parsed.value

            if (client) {
                console.log("SSE Event: client.", parsed)
                if (seeded) {
                    setClients(src => upsert(client.id, client, src))
                } else {
                    seedClients.set(client.id, client)
                }
            }
            if (thing) {
                console.log("SSE Event: thing.", parsed)
                if (seeded) {
                    setThings(src => upsert(thing.name, thing, src))
                } else {
                    seedThings.set(thing.name, thing)
                }
            }
            if (item) {
                console.log("SSE Event: item.", parsed)
                if (seeded) {
                    setItems(src => upsert(itemKey(item.thing, item.name), item, src))
                } else {
                    seedItems.set(itemKey(item.thing, item.name), item)
                }
            }
            if (value) {
                console.log("SSE Event: value.", parsed)
                if (seeded) {
                    setValues(src => upsert(itemKey(value.thing, value.item), value, src))
                } else {
                    seedValues.set(itemKey(value.thing, value.item), value)
                }
            }
        })

        source.onerror = () => {
            handleDisconnect()
            source.close()
            retry()
        }
        source.onopen = () => {
            handleConnect()
        }

        return () => {
            if (source.readyState !== source.CLOSED) {
                source.close()
            }
            setOnline(false)
            cancel()
        }
    }, [active, unit, site.ID, filter, onMessage, handleConnect, handleDisconnect, attempt, retry, cancel, t])

    return {
        online,
        clients,
        things,
        items,
        values,
    }
}

function tokenize(input: string): (string | number)[] {
    const tokens = [];
    let match;

    while ((match = regexThingsTokenize.exec(input)) !== null) {
        const token = match[0];
        tokens.push(isNaN(Number(token)) ? token : Number(token));
    }

    return tokens;
}

function tokenPriority(input: string | number): number {
    if (input === ".") {
        return 1
    }
    if (input === "_") {
        return 2
    }
    if (typeof input === "number") {
        return 3
    }
    return 4
}

function sortThingsByLexicalAndNumber(a: string, b: string): number {
    const tokenA = tokenize(a);
    const tokenB = tokenize(b);

    const length = Math.min(tokenA.length, tokenB.length);

    for (let i = 0; i < length; i++) {
        const tA = tokenA[i];
        const tB = tokenB[i];

        if (tA === tB) {
            continue
        }

        const tAPriority = tokenPriority(tA)
        const tBPriority = tokenPriority(tB)

        if (tAPriority !== tBPriority) {
            return tAPriority - tBPriority
        }
        if (typeof tA === "number" && typeof tB === "number") {
            return tA - tB;
        }
        if (typeof tA === "string" && typeof tB === "string") {
            return tA.localeCompare(tB);
        }
    }
    return tokenA.length - tokenB.length;
}

export const useIoTValues = (
    filterClass: string,
    filterItem: string,
    clients: Map<string, Client>,
    items: Map<string, Item>,
    values: Map<string, ItemValue>,
): ValidValue[] => {
    const result = useMemo(() => {
        const r =
            Array.from(values.values())
                .filter(value => (
                    value.item === filterItem &&
                    value.value.labels?.get("class") === filterClass &&
                    clients.get(value.value.clientId)?.manifest.status === "CONNECTED" &&
                    clients.get(items.get(itemKey(value.thing, value.item))?.manifest.clientId || "")?.manifest.status === "CONNECTED"
                ))
                .map(
                    value => ({
                        item: value.item,
                        thing: value.thing,
                        labels: value.value.labels,
                        annotations: items.get(itemKey(value.thing, value.item))?.manifest.annotations,
                        value: value.value.number,
                        timestamp: value.value.timestamp,
                    })
                )
        console.log("SSE valid values", Array.from(values.values()), r)
        return r.sort((a, b) => sortThingsByLexicalAndNumber(a.thing, b.thing))
    }, [filterClass, filterItem, clients, items, values])
    return result
}

export const useIoTCommands = (site: Site) => {
    const send = useCallback(
        (thing: string, item: string, value: number) => {
            const command = {
                command: {
                    thing: thing,
                    item: item,
                    command: {
                        number: value,
                    },
                },
            }
            http(`Sending command`, monoEndpointURL(`sites/${site.ID}/commands`), noSnackBar, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(command),
            })
                .then((_) => console.log("Successfuly set item"))
                .catch((e) => console.log(e))
        },
        [site.ID]
    )

    return { send }
}

export const thingIndex = (name: string) => +(name.split("_").at(-1) || 0)
