import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { Customer, Site } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { CustomerInfo } from "../components/CustomerInfo"
import { IdentifierManagementDialog } from "../components/IdentifierManagementDialog"
import { UnitAppBar } from "../components/UnitAppBar"
import { UserManagementDialog } from "../components/UserManagementDialog"
import { minPageWidth } from "../config/sizing"

export interface CustomersProps {
    whoAmI: WhoAmI
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
    archiveSite: (site: Site) => void
}

export function Customers(props: CustomersProps) {
    const { whoAmI, customers, showArchived, setShowArchived, archiveSite } = props

    const [customerUserManagement, setCustomerUserManagement] = React.useState(0)
    const [identifierManagement, setIdentifierManagement] = React.useState(0)
    const { t } = useTranslation()

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar
                whoAmI={whoAmI}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
            <Stack sx={{ py: 2, px: 1 }}>
                <Stack direction="row" alignItems="center" px={2} spacing={1}>
                    <Typography variant="h5">{t("offering.customers")}</Typography>
                </Stack>
                {customers.map((customer) => (
                    <CustomerInfo
                        key={`customer-info-${customer.ID}`}
                        customer={customer}
                        onManageUsers={() => setCustomerUserManagement(customer.ID)}
                        onManageIdentifiers={() => setIdentifierManagement(customer.ID)}
                        showArchivedSites={showArchived}
                        archiveSite={archiveSite}
                    />
                ))}
            </Stack>
            <UserManagementDialog
                actor={whoAmI.User}
                customerID={customerUserManagement}
                customers={customers}
                onClose={() => setCustomerUserManagement(0)}
            />
            <IdentifierManagementDialog customerID={identifierManagement} onClose={() => setIdentifierManagement(0)} />
        </Box>
    )
}
