import { CheckBoxOutlineBlank, DeleteForever } from "@mui/icons-material"
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { Fragment, ReactElement, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { asCameraSubject, Operation } from "../../api/Authz"
import { Site, Unit } from "../../api/Customer"
import { CreateTimelapseTaskRequest, TimelapseTask } from "../../api/TimelapseTask"
import useAuthorizer from "../../auth/AuthorizerProvider"
import { http, snackbarSuccess } from "../../backend/request"
import { request } from "../../config/headers"
import { setTimeToEndOfDay } from "../../config/time"
import { endpointURL } from "../../config/urls"
import { Node, Selected, useTreeSet } from "../../hooks/treeset"
import { EventFilter } from "../../services/EventFilter"
import { Cameras } from "./SnapshotPresetList"

export interface TimelapseExportDialogProps {
    initialFilter: EventFilter
    site: Site
    unit: Unit
    cameras: Cameras[]
    parent?: Element
    onClose: () => void
}

const maxCameraPresets = 50
const labelSelectAll = "dialog.snapshot.all_cameras"

interface camera {
    CameraID: number
    Preset: string
}

export function TimelapseExportDialog(props: TimelapseExportDialogProps) {
    const { cameras, initialFilter, site, unit, parent, onClose } = props
    const { t } = useTranslation()
    const snackbar = useSnackbar()

    const [error, setError] = useState("")

    const [includeTimelapse, setIncludeTimelapse] = useState(true)
    const [includeSnapshot, setIncludeSnapshot] = useState(false)

    const [startTime, setStartTime] = useState<Date | null>(null)
    const [endTime, setEndTime] = useState<Date | null>(null)
    const [predefinedDateRange, setPredefinedDateRange] = useState("1")
    const [datePicker, setDatePicker] = useState<boolean>(false)

    const { allowOperation } = useAuthorizer()

    const tree = useMemo(() => {
        const c = cameras.map((c) => {
            const allowed = allowOperation(Operation.EXPORT_CAMERA_TIMELAPSE, asCameraSubject(unit, c.id))
            return {
                id: `${c.id}`,
                disable: !c.presets.length || !allowed,
                children: c.presets.map((p) => ({
                    id: `${c.id}/${p.preset}`,
                    disable: !allowed,
                    value: {
                        CameraID: c.id,
                        Preset: p.preset,
                    },
                })),
            }
        })

        const disable = !c.some((e) => e.disable === false)
        return {
            id: labelSelectAll,
            disable: disable,
            children: c,
        }
    }, [cameras, unit, allowOperation])
    const { ids, values, nodes, flip } = useTreeSet(tree, initialFilter.ids)

    useEffect(() => {
        const currentDate = new Date()
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth())
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        setTimeRange(firstDayOfMonth, lastDayOfMonth)
    }, [])

    useEffect(() => {
        if (ids.size === 0) {
            setError(t("dialog.snapshot.no_cameras_error"))
            return
        }
        if (ids.size > maxCameraPresets) {
            setError(t("dialog.snapshot.max_cameras_error", { max: maxCameraPresets }))
            return
        }

        if (!includeSnapshot && !includeTimelapse) {
            setError(t("dialog.snapshot.include_error"))
            return
        }

        const currentTime = new Date()
        if (startTime && startTime.getTime() > currentTime.getTime()) {
            setError(t("dialog.snapshot.start_time_in_future_error"))
            return
        }
        if (endTime && startTime && startTime.getTime() >= endTime.getTime()) {
            setError(t("dialog.snapshot.start_time_before_end_time_error"))
            return
        }

        if (startTime && !DateTime.fromJSDate(startTime).isValid) {
            setError(t("dialog.snapshot.start_time_invalid"))
            return
        }

        if (endTime && !DateTime.fromJSDate(endTime).isValid) {
            setError(t("dialog.snapshot.end_time_invalid"))
            return
        }
        setError("")
    }, [ids, t, includeSnapshot, includeTimelapse, endTime, startTime])

    const onAccept = () => {
        const cameras = new Map<number, string[]>()
        values.forEach((v) => {
            var list = cameras.get(v.CameraID) || []
            list.push(v.Preset)
            cameras.set(v.CameraID, list)
        })
        sendExportRequest({
            start: startTime,
            end: endTime,
            sites: [
                {
                    id: site.ID,
                    units: [
                        {
                            id: unit.ID,
                            cameras: Array.from(cameras.entries()).map(([k, v]) => {
                                return {
                                    id: k,
                                    presets: v.map((p) => {
                                        return {
                                            preset: p,
                                        }
                                    }),
                                }
                            }),
                        },
                    ],
                },
            ],
            include: {
                snapshot: includeSnapshot,
                timelapse: includeTimelapse,
            },
        })
    }

    const sendExportRequest = (r: CreateTimelapseTaskRequest) => {
        http<TimelapseTask>("Creating timelapse task", endpointURL("queues/timelapse/tasks"), snackbar, {
            method: "POST",
            headers: request.headers,
            body: JSON.stringify(r),
        })
            .then((task) => {
                console.log("Successfuly created task: ", task)
                snackbarSuccess(t("dialog.snapshot.success", { email: task.payload.email }), snackbar)
            })
            .catch((e) => console.log(e))
        onClose()
    }

    const renderLabel = (node: Node<camera>) => {
        if (node.id === labelSelectAll) return t(labelSelectAll)
        if (!!node.value?.Preset) return node.value.Preset
        return t("camera.nth_camera", { id: node.id })
    }

    const renderTree = (node: Node<camera>, indent: number): ReactElement => {
        return (
            <Fragment key={node.id}>
                <FormControlLabel
                    label={renderLabel(node)}
                    style={{ paddingLeft: `${28 * indent}px` }}
                    control={
                        <Checkbox
                            checked={nodes.get(node.id) === Selected.YES}
                            indeterminate={nodes.get(node.id) === Selected.PARTIAL}
                            onChange={() => flip(node.id)}
                            size="small"
                            disabled={node.disable}
                            indeterminateIcon={<CheckBoxOutlineBlank />}
                            style={{ padding: "4px" }}
                        />
                    }
                />
                {node.children?.map((ch) => renderTree(ch, indent + 1))}
            </Fragment>
        )
    }

    const renderCameras = () => {
        return (
            <Stack>
                <Typography pt="0.5em" fontWeight="bold">
                    {t("dialog.snapshot.cameras")}
                </Typography>
                {renderTree(tree, 1)}
            </Stack>
        )
    }

    const renderIncludeOptions = () => {
        return (
            <Box>
                <Typography pt="0.5em" fontWeight="bold">
                    {t("dialog.snapshot.export")}
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeTimelapse}
                            onChange={() => setIncludeTimelapse(!includeTimelapse)}
                            color="primary"
                            size="small"
                        />
                    }
                    label={t("dialog.snapshot.timelapse_checkbox")}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeSnapshot}
                            onChange={() => setIncludeSnapshot(!includeSnapshot)}
                            color="primary"
                            size="small"
                        />
                    }
                    label={t("dialog.snapshot.snapshot_checkbox")}
                />
            </Box>
        )
    }

    const setTimeRange = (start: Date | null, end: Date | null) => {
        setStartTime(start)
        if (end) {
            setEndTime(setTimeToEndOfDay(end))
        } else {
            setEndTime(end)
        }
    }

    const renderPredefinedDateRange = () => {
        return (
            <ToggleButtonGroup
                color="primary"
                value={predefinedDateRange}
                exclusive
                onChange={(e, v) => {
                    if (v === null) {
                        return
                    }
                    setPredefinedDateRange(v)
                    const currentDate = new Date()
                    switch (v) {
                        case "1":
                            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth())
                            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                            setTimeRange(firstDayOfMonth, lastDayOfMonth)
                            break
                        case "2":
                            const lastMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
                            const lastMonthLastDay = new Date(
                                lastMonthFirstDay.getFullYear(),
                                lastMonthFirstDay.getMonth() + 1,
                                0
                            )
                            setTimeRange(lastMonthFirstDay, lastMonthLastDay)
                            break
                        case "3":
                            const startOfYear = new Date(currentDate.getFullYear(), 0)
                            setTimeRange(startOfYear, currentDate)
                            break
                        case "4":
                            setTimeRange(null, null)
                            break
                        case "5":
                            setDatePicker(true)
                            return
                    }
                    setDatePicker(false)
                }}
            >
                <ToggleButton value="1">{t("dialog.snapshot.current_month")}</ToggleButton>
                <ToggleButton value="2">{t("dialog.snapshot.last_month")}</ToggleButton>
                <ToggleButton value="3">{t("dialog.snapshot.current_year")}</ToggleButton>
                <ToggleButton value="4">{t("dialog.snapshot.all")}</ToggleButton>
                <ToggleButton value="5">{t("dialog.snapshot.custom")}</ToggleButton>
            </ToggleButtonGroup>
        )
    }

    const renderDateRangePicker = () => {
        return (
            <Box>
                <Typography pt="0.5em" fontWeight="bold" paddingBottom={1}>
                    {t("dialog.snapshot.export_range")}
                </Typography>
                {renderPredefinedDateRange()}
                <Stack spacing={1} direction="row" paddingTop={2}>
                    <DatePicker
                        disabled={!datePicker}
                        disableFuture
                        label={t("dialog.snapshot.start_time")}
                        value={startTime ? DateTime.fromJSDate(startTime) : null}
                        onChange={(date) => {
                            setStartTime(date ? date.toJSDate() : null)
                        }}
                    />
                    <DatePicker
                        disabled={!datePicker}
                        label={t("dialog.snapshot.end_time")}
                        value={endTime ? DateTime.fromJSDate(endTime) : null}
                        onChange={(date) => {
                            if (!date) {
                                setEndTime(null)
                            } else {
                                const jsDate = date.toJSDate()
                                setEndTime(setTimeToEndOfDay(jsDate))
                            }
                        }}
                    />
                    <Tooltip title={t("dialog.snapshot.clear_tooltip")} arrow>
                        <IconButton
                            color="secondary"
                            disabled={(!startTime && !endTime) || !datePicker}
                            onClick={() => {
                                setTimeRange(null, null)
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        )
    }

    return (
        <Dialog
            onAbort={onClose}
            onClose={onClose}
            onSubmit={onClose}
            container={parent}
            aria-labelledby="timelape-export-dialog-title"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle id="timelapse-export-dialog-title">
                {t("dialog.snapshot.title")}
                <Divider variant="fullWidth" flexItem />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        {renderDateRangePicker()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderIncludeOptions()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderCameras()}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {error && (
                    <Typography fontSize="small" variant="body2" color="error" paddingRight={2}>
                        {error}
                    </Typography>
                )}
                <Button onClick={onAccept} variant="contained" disabled={!!error}>
                    {t("action.ok")}
                </Button>
                <Button onClick={onClose} variant="outlined">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
