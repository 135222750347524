import {
    Cloud,
    Help,
    Leaderboard,
    MeetingRoom,
    NotificationsActive,
    PhotoCamera,
    PinDrop,
    Videocam,
    VideoLibrary,
} from "@mui/icons-material"
import { OfferingType } from "../api/Customer"

export interface ServiceIconProps {
    offering: OfferingType
}

export const ServiceIcon = (props: ServiceIconProps) => {
    const { offering } = props

    switch (offering) {
        case OfferingType.Access:
            return <MeetingRoom fontSize="small" />
        case OfferingType.Alarm:
            return <NotificationsActive fontSize="small" />
        case OfferingType.Video:
            return <Videocam fontSize="small" />
        case OfferingType.Recording:
            return <VideoLibrary fontSize="small" />
        case OfferingType.Weather:
            return <Cloud fontSize="small" />
        case OfferingType.Statistics:
            return <Leaderboard fontSize="small" />
        case OfferingType.Tracking:
            return <PinDrop fontSize="small" />
        case OfferingType.Timelapse:
            return <PhotoCamera fontSize="small" />
    }
    return <Help fontSize="small" />
}
