import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useFullscreen } from "rooks"
import { ETDialogTitle } from "../components/ETDialogTitle"
import useAuth from "./AuthProvider"

export const ExpiredDialog = () => {
    const { expired } = useAuth()
    const { t } = useTranslation()
    const { fullscreenElement } = useFullscreen()

    if (!expired) {
        return null
    }

    return (
        <Dialog maxWidth="xs" fullWidth open={true} container={fullscreenElement}>
            <ETDialogTitle title={t("dialog.expiration.title")} />
            <DialogContent>
                <Typography>{t("message.logged_out")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => window.location.reload()} variant="contained">
                    {t("action.login")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
