import { Box, Grid, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Site, Unit } from "../../api/Customer"
import { OneDayRange, RangeChoice } from "../common/RangeChoice"
import { NewMultiChart } from "../common/NewMultiChart"
import { useIoT, useIoTValues } from "../../services/IoT"
import { ConnectionStatus } from "../common/ConnectionStatus"

export interface StatisticsProps {
    unit: Unit
    site: Site
}

const classes = ["solar_panel", "battery", "load"]
const redColors = ["C7253E", "#C40C0C", "#FF6500", "#FF8A08", "#FFC100"]
const greenColors = ["#16FF00", "#00FFAB", "#B8F1B0", "#9CFF2E", "#38E54D"]

export function NewStatistics(props: StatisticsProps) {
    const { unit, site } = props

    const { t } = useTranslation()

    const { online, clients, items, values } = useIoT(site, unit.ShortName, true, classes)
    const solarPanelPowers = useIoTValues("solar_panel", "power", clients, items, values)
    const batteryPercentages = useIoTValues("battery", "percentage", clients, items, values)
    const batteryVoltages = useIoTValues("battery", "voltage", clients, items, values)
    const loadPower = useIoTValues("load", "power", clients, items, values)
    const [range, setRange] = useState(OneDayRange)

    const batteryVoltageLevels = (batteryInfo: Map<string, string> | undefined) => {
        if (batteryInfo === undefined) return

        const levels = []
        const full = batteryInfo.get("voltage_full")
        if (full) {
            levels.push({
                label: t("statistics.battery_full"),
                value: +full,
                color: "green",
            })
        }
        const low = batteryInfo.get("voltage_low")
        if (low) {
            levels.push({
                label: t("statistics.battery_low"),
                value: +low,
                color: "orange",
            })
        }
        const off = batteryInfo.get("voltage_off")
        if (off) {
            levels.push({
                label: t("statistics.battery_off"),
                value: +off,
                color: "red",
            })
        }
        return levels
    }

    const createLabelForBattery = (id: number, annotations?: Map<string, string>) => {
        const technology = annotations?.get("technology")
        const name = t("statistics.battery", { idx: id })
        return technology ? `${name} (${technology})` : name
    }

    return (
        <Stack sx={{ p: 4, pt: 2, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <ConnectionStatus online={online} />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.solar_panels")}</Typography>
                    </Grid>
                    <NewMultiChart
                        siteID={site.ID}
                        unit={unit}
                        itemValues={solarPanelPowers}
                        label={(id) => t("statistics.solar_panel", { idx: id })}
                        timeOffsetMS={range.millis}
                        valueUnit=" W"
                        colorPalette={redColors}
                    />

                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.batteries")}</Typography>
                    </Grid>
                    <NewMultiChart
                        siteID={site.ID}
                        unit={unit}
                        itemValues={batteryPercentages}
                        label={createLabelForBattery}
                        timeOffsetMS={range.millis}
                        valueUnit="%"
                        colorPalette={greenColors}
                        startValue={0}
                        endValue={100}
                    />

                    {batteryVoltages.map((battery) => (
                        <NewMultiChart
                            key={battery.thing}
                            siteID={site.ID}
                            unit={unit}
                            itemValues={[battery]}
                            label={createLabelForBattery}
                            timeOffsetMS={range.millis}
                            valueUnit=" V"
                            rulers={batteryVoltageLevels(battery.annotations)}
                            colorPalette={greenColors}
                        />
                    ))}

                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.loads")}</Typography>
                    </Grid>
                    <NewMultiChart
                        siteID={site.ID}
                        unit={unit}
                        itemValues={loadPower}
                        label={(id) => t("statistics.load", { idx: id })}
                        timeOffsetMS={range.millis}
                        valueUnit=" W"
                        colorPalette={redColors}
                    />
                </Grid>
            </Box>
        </Stack>
    )
}
