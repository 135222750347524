import { DialogTitle, Divider, Stack, Typography } from "@mui/material"

export interface ETDialogTitleProps {
    title: string
}

export const ETDialogTitle = (props: ETDialogTitleProps) => {
    const { title } = props

    return (
        <DialogTitle>
            <Stack alignItems="start" width="100%">
                <Typography variant="h6">{title}</Typography>
                <Divider variant="fullWidth" flexItem />
            </Stack>
        </DialogTitle>
    )
}
