import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { Site, Unit } from "../api/Customer"
import { Live, SetDelay } from "../api/Video"
import { Grid1x1, Grid2x1, Grid2x2, Paging, SanitizePinned, Unpinned, ViewOrDefault } from "../config/paging"
import { UnitCameras } from "../features/video/UnitCameras"

export interface VideoProps {
    site: Site
    units: Unit[]
}

const asNumber = (key: string, defaultValue: number, params: URLSearchParams) => {
    const value = params.get(key)
    if (!value) {
        return defaultValue
    }
    return +value
}

const asString = (key: string, params: URLSearchParams) => {
    const value = params.get(key)
    if (!value) {
        return ""
    }
    return value
}

const asArray = (key: string, params: URLSearchParams): number[] => {
    const value = params.getAll(key)
    if (value.length === 0) {
        return []
    }
    return value.map((v) => (+v || 0) - 1)
}

export const Video = (props: VideoProps) => {
    const { site, units } = props

    const [searchParams] = useSearchParams()

    const initialPlayback = useMemo(() => {
        const encoded = searchParams.get("timestamp")
        if (!encoded) {
            return Live
        }
        return SetDelay(Live, Date.now() - +encoded)
    }, [searchParams])

    const initialPaging = useMemo((): Paging => {
        const count = units.flatMap((u) => u.UnitConfig.Cameras || []).length || 0
        const defaultView = count > 2 ? Grid2x2 : count > 1 ? Grid2x1 : Grid1x1
        const view = ViewOrDefault(asString("view", searchParams), defaultView)
        const page = asNumber("page", 0, searchParams)
        const pinned = SanitizePinned(asArray("lock", searchParams), count)

        return {
            View: view,
            Page: page,
            Pages: 0,
            Pinned: pinned,
            Unpinned: Unpinned(pinned, count),
            Count: count,
        }
    }, [searchParams, units])

    return (
        <UnitCameras
            key={units.map((u) => u.ShortName).join("-")}
            site={site}
            units={units}
            initialPlayback={initialPlayback}
            initialPaging={initialPaging}
        />
    )
}
