import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Customer, offeringOperation, OfferingType, Site, Unit } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import { UnitPage } from "../components/UnitPage"
import { NewAlarm } from "../features/newalarm/Alarm"

export interface NewUnitAlarmProps {
    whoAmI: WhoAmI
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

const offering = OfferingType.Alarm

export function NewUnitAlarm(props: NewUnitAlarmProps) {
    const { whoAmI, customers, showArchived, setShowArchived } = props

    const { shortName } = useParams()

    const unit = useMemo(
        () => customers.flatMap((c) => c.Units).find((u) => u.ShortName === shortName),
        [customers, shortName]
    )
    const site = useMemo(() => customers.flatMap((c) => c.Sites).find((s) => s.ID === unit?.SiteID), [customers, unit])
    const customer = useMemo(() => customers.find((c) => c.ID === site?.CustomerID), [customers, site])
    const allowed = useUnitPermission(offeringOperation(offering), unit)

    return (
        <UnitPage
            whoAmI={whoAmI}
            offering={offering}
            unit={unit}
            site={site}
            customer={customer}
            customers={customers}
            allowed={allowed}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            render={(unit: Unit, site: Site) => <NewAlarm site={site} unit={unit} />}
        />
    )
}
