import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const LoadingPage = () => {
    const { t } = useTranslation()

    return (
        <Stack direction="row" width="100%">
            <Box flexGrow={1} />
            <Stack height="100vh" flexGrow={0.5} spacing={0.5}>
                <Box flexGrow={1} />
                <Typography variant="button">{t("message.loading")}</Typography>
                <LinearProgress />
                <Box flexGrow={1} />
            </Stack>
            <Box flexGrow={1} />
        </Stack>
    )
}
