import { Operation } from "./Authz"

export interface Customer {
    ID: number
    DisplayName: string
    Units: Unit[]
    Sites: Site[]
}

export enum OfferingType {
    Unknown = "Unknown",
    Access = "Access",
    Alarm = "Alarm",
    Recording = "Recording",
    Statistics = "Statistics",
    Timelapse = "Timelapse",
    Tracking = "Tracking",
    Video = "Video",
    Weather = "Weather",
}

const offeringToOperation = new Map([
    [OfferingType.Access, Operation.UI_VIEW_ACCESS],
    [OfferingType.Alarm, Operation.UI_VIEW_ALARM],
    [OfferingType.Video, Operation.UI_VIEW_VIDEO],
    [OfferingType.Recording, Operation.UI_VIEW_VIDEO],
    [OfferingType.Weather, Operation.UI_VIEW_WEATHER],
    [OfferingType.Statistics, Operation.UI_VIEW_STATISTICS],
    [OfferingType.Tracking, Operation.UI_VIEW_TRACKING],
    [OfferingType.Timelapse, Operation.UI_VIEW_TIMELAPSE],
])

export const offeringOperation = (offering?: OfferingType) => {
    const operation = offeringToOperation.get(offering || OfferingType.Unknown)
    return operation || Operation.OPERATION_UNSPECIFIED
}

export const hasOffering = (unit: Unit, offering?: OfferingType) => !!unit?.Offerings?.some((o) => o.DisplayName === offering)

export interface Offering {
    ID: number
    DisplayName: OfferingType
}

export interface Unit {
    ID: number
    SiteID: number
    CustomerID: number
    Offerings?: Offering[]
    DisplayName: string
    Type: UnitType
    ShortName: string
    Revision: number
    UnitConfig: UnitConfig
}

const shortNameRE = /^([a-zA-Z]*)([0-9]*).*$/

const splitShortName = (u: Unit) => {
    const parts = u.ShortName.match(shortNameRE)
    if (parts === null || parts.length !== 3) {
        return {
            code: "",
            number: 0,
        }
    }
    return {
        code: parts[1],
        number: parseInt(parts[2]),
    }
}

export const compareUnits = (u1: Unit, u2: Unit) => {
    const s1 = splitShortName(u1)
    const s2 = splitShortName(u2)

    if (s1.code > s2.code || (s1.code === s2.code && s1.number > s2.number)) {
        return 1
    }
    if (s1.code < s2.code || (s1.code === s2.code && s1.number < s2.number)) {
        return -1
    }
    return 0
}

export enum Theme {
    THEME_UNSPECIFIED = "THEME_UNSPECIFIED",
    DARK = "DARK",
    LIGHT = "LIGHT",
}

export interface Branding {
    DefaultTheme: Theme
    Logos?: Logo[]
}

export interface Logo {
    Theme?: Theme
    SmallURL?: string
    LargeURL?: string
}

export enum UnitType {
    City = "City",
    Heavy = "Heavy",
    Hub = "Hub",
    Light = "Light",
    Micro = "Micro",
    Site = "Site",
    SmartOffice = "SmartOffice",
}

export interface Site {
    ID: number
    CustomerID: number
    DisplayName: string
    SiteConfig: SiteConfig
    Archived: string | undefined
}

export interface SiteConfig {
    region?: string
}

export interface UnitConfig {
    Cameras?: CameraConfig[]
    SnapshotCameraID?: number
}

export interface CameraConfig {
    ID: number
    RelativePanTilt?: RelativePanTilt
    RelativeZoom?: RelativeZoom
}

export interface RelativePanTilt {
    MinPanStep?: number
    MinTiltStep?: number
}

export interface RelativeZoom {
    MinZoomStep?: number
}
