import { Box, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export interface ImageWithLoadingProps {
    src: string | string[]
    alt: string
    errorText?: string
    onLoaded?: () => void
}

export function ImageWithLoading(props: ImageWithLoadingProps) {
    const { src, alt, errorText, onLoaded } = props
    const srcArray = Array.isArray(src) ? src : [src]
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const { t } = useTranslation()

    const [currentIndex, setCurrentIndex] = useState<number>(0)

    const handleLoad = () => {
        if (isLoading) {
            setIsLoading(false)
            onLoaded?.()
        }
    }

    const handleError = () => {
        if (currentIndex < srcArray.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            setIsError(true)
            setIsLoading(false)
        }
    }

    const hidden = isLoading
    const currentSrc = srcArray[currentIndex]

    return (
        <Box>
            {(isLoading || isError) && (
                <Stack
                    alignItems="center"
                    sx={{
                        width: "100%",
                        aspectRatio: 640 / 360,
                        backgroundColor: "#758da0",
                    }}
                >
                    <Stack direction="row" alignItems="center" height="85%">
                        <Typography variant="h5" fontWeight="bold" color="#d5d5d5">
                            {isLoading && t("message.loading").toUpperCase()}
                            {isError && (errorText || t("message.no_data")).toUpperCase()}
                        </Typography>
                    </Stack>
                </Stack>
            )}
            {!isError && (
                <img
                    src={currentSrc}
                    width="100%"
                    onLoad={handleLoad}
                    onError={handleError}
                    alt={alt}
                    loading="lazy"
                    style={{
                        display: "block",
                        zIndex: hidden ? -1 : undefined,
                        position: hidden ? "absolute" : undefined,
                    }}
                />
            )}
        </Box>
    )
}
