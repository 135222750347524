import { Second } from "../config/time"

export interface Playback {
    Delay: number
    IssuedAt: number
    Paused: boolean
    Speed: number
}

// This is to avoid rounding errors when jumping back and forward in time. Should be small enough
// to not be noticeable otherwise.
const LiveThreshold = 3 * Second

export const Paused = (playback: Playback) => {
    const now = Date.now()
    return NewPlayback(CurrentDelay(playback, now), true, playback.Speed, now)
}
export const Resumed = (playback: Playback) => {
    const now = Date.now()
    return NewPlayback(CurrentDelay(playback, now), false, playback.Speed, now)
}
export const AddDelay = (playback: Playback, delay: number) => {
    const now = Date.now()
    return NewPlayback(CurrentDelay(playback, now) + delay, playback.Paused, playback.Speed, now)
}
export const SetDelay = (playback: Playback, delay: number) => NewPlayback(delay, playback.Paused, playback.Speed)
export const SetTime = (playback: Playback, time: number) => {
    const now = Date.now()
    return NewPlayback(now - time, playback.Paused, playback.Speed, now)
}
export const SetSpeed = (playback: Playback, speed: number) => {
    const now = Date.now()
    return NewPlayback(CurrentDelay(playback, now), playback.Paused, speed, now)
}
export const NewPlayback = (delay: number, paused: boolean, speed: number, now?: number) => {
    if (now === undefined) {
        now = Date.now()
    }
    if (delay <= LiveThreshold) {
        delay = 0
        speed = 1
        paused = false
    }
    return {
        IssuedAt: Math.round(now),
        Delay: Math.round(delay),
        Paused: paused,
        Speed: speed,
    }
}
export const Live = NewPlayback(0, false, 1)

export const Sanitize = (playback: Playback) => {
    if (IsLive(playback)) {
        return Live
    }
    if (playback.Paused) {
        return playback
    }
    const now = Date.now()
    return NewPlayback(CurrentDelay(playback, now), playback.Paused, playback.Speed, now)
}

export const IsLive = (playback: Playback) => {
    return CurrentDelay(playback) <= LiveThreshold
}

export const CurrentDelay = (playback: Playback, now?: number) => {
    if (now === undefined) {
        now = Date.now()
    }
    const age = now - playback.IssuedAt
    const elapsed = playback.Paused ? 0 : age * playback.Speed
    return Math.max(0, age + playback.Delay - elapsed)
}

export const CurrentTime = (playback: Playback, now?: number) => {
    if (now === undefined) {
        now = Date.now()
    }
    return now - CurrentDelay(playback, now)
}

export const EqualPlayback = (p1: Playback, p2: Playback, tolerance?: number) => {
    if (IsLive(p1) && IsLive(p2)) {
        return true
    }
    if (p1.Speed !== p2.Speed || p1.Paused !== p2.Paused) {
        return false
    }

    const now = Date.now()
    const d1 = CurrentDelay(p1, now)
    const d2 = CurrentDelay(p2, now)

    return Math.abs(d1 - d2) <= (tolerance || 0)
}
