
// UpsertSorted inserts new elements or update exisiting elements based on the 
// field `id` in an array and returns sorted array. 
export const UpsertSorted = <T extends { id: number }>(array: T[], newElement: T): T[] => {
    const index = array.findIndex((element) => element.id >= newElement.id);

    if (index === -1) {
        return [...array, newElement];
    } else if (array[index].id === newElement.id) {
        return [...array.slice(0, index), newElement, ...array.slice(index + 1)];
    } else {
        return [...array.slice(0, index), newElement, ...array.slice(index)];
    }
};
